import React, {useContext} from 'react';
import defaultState from './defaultState';


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/remote-config';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

import { firebaseConfig } from './firebaseConfig';

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
};



export const firebaseApp = firebase.app();

export const firestore = firebase.firestore();

export const functions = firebase.functions();

export const StateContext = React.createContext(defaultState);
export default StateContext;


export const useMainStore = () => useContext(StateContext);
