import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import StateContext, { firestore } from '@/store/StateContext';
import moment from 'moment';
import { generateDateLabels, handleDefinition } from '@/helpers/stats';

import { ordersDefinition, productsOverallDefinition, soldProductsDefinition } from './definitions';

import { ORDER_STATUS } from '@/variables/orders';

export const StatsDataContext = createContext({});
function StatsData({ children }) {
    const [initialized, setInitialized] = useState(false);
    const provider = useContext(StateContext);
    const [data, setData] = useState({});
    const [rawData, setRawData] = useState({});
    const [statsK, setStatsK] = useState('stats');

    const [dateRange, setDateRange] = useState({
        start: moment().subtract(7, 'days').startOf('day'),
        end: moment().endOf('day')
    });
    const [dateRangeLabels, setDateRangeLabels] = useState([]);

    useEffect(() => {
        setDateRangeLabels(generateDateLabels(dateRange.start, dateRange.end));
    }, []);

    useEffect(() => {
        if (!initialized) return;

        load();
    }, [dateRangeLabels, statsK]);

    //   if (process?.env && process.env.NODE_ENV === 'development') console.log('StatsData', data);

    const updateStats = (string) => {
        setStatsK(string);
    };

    const updateDateRange = useCallback(
        (start, end) => {
            //check if are moment
            if (!moment.isMoment(start) || !moment.isMoment(end)) {
                console.error('start and end must be moment');
                return;
            }

            if (start.isSame(dateRange.start) && end.isSame(dateRange.end)) return;

            setDateRangeLabels(generateDateLabels(start, end));

            setDateRange({
                start: start.startOf('day'),
                end: end.endOf('day')
            });
        },
        [setDateRange]
    );

    const addData = useCallback(
        (key, values) => {
            setData((_data) => {
                return {
                    ..._data,
                    [key]: values
                };
            });
        },
        [setData]
    );

    function load() {
        setData({});
        setInitialized(true);
        loadOrders();
        loadOrdersStats();
        loadStoreProductsOverall();
        loadProductsOverall();
        loadProductsStats();
    }

    async function loadOrders() {
        try {
            const querySnapshot = await firestore
                .collection(`business/${provider.business.id}/orders`)
                .where(
                    'status',
                    '==',
                    statsK === 'stats' ? ORDER_STATUS.CLOSED : ORDER_STATUS.ARCHIVED
                )
                .where('createdAt', '>=', dateRange.start.toDate())
                .where('createdAt', '<=', dateRange.end.toDate())
                .orderBy('createdAt', 'asc')
                .get();

            const orders = [];
            querySnapshot.forEach((doc) => {
                orders.push({ id: doc.id, ...doc.data() });
            });

            addData('orders', orders);
        } catch (e) {
            console.error(e);
        }
    }

    async function loadOrdersStats() {
        try {
            const querySnapshot = await firestore
                .collection(
                    `business/${provider.business.id}/${statsK}/orders/${
                        statsK !== 'stats' ? 'a' : ''
                    }sold`
                )
                .where('createdAt', '>=', dateRange.start.toDate())
                .where('createdAt', '<=', dateRange.end.toDate())
                .orderBy('createdAt', 'asc')
                .get();

            const orderStats = ordersDefinition(dateRangeLabels);
            const orders = handleDefinition(querySnapshot, orderStats);

            setRawData((_data) => ({ ..._data, ordersStats: orders }));

            const _data = {};
            // TODO: Centralizzare
            Object.keys(orderStats).forEach((key) => {
                _data[key] = !orderStats[key]?.handle
                    ? Object.values(orderStats[key])
                    : orderStats[key];
            });
            addData('orderStats', _data);
        } catch (e) {
            console.error(e);
        }
    }

    async function loadStoreProductsOverall() {
        try {
            const querySnapshot = await firestore
                .collection(`business/${provider.business.id}/${statsK}/storeProducts/overall`)
                .where('createdAt', '>=', dateRange.start.toDate())
                .where('createdAt', '<=', dateRange.end.toDate())
                .orderBy('createdAt', 'asc')
                .get();

            const storeProductsStats = productsOverallDefinition(dateRangeLabels);
            const storeProducts = handleDefinition(querySnapshot, storeProductsStats);

            setRawData((_data) => ({ ..._data, storeProducts: storeProducts }));

            // TODO: Centralizzare
            const _data = {};
            Object.keys(storeProductsStats).forEach((key) => {
                _data[key] = !storeProductsStats[key]?.handle
                    ? Object.values(storeProductsStats[key])
                    : storeProductsStats[key];
            });
            addData('storeProductsOverall', _data);
        } catch (e) {
            console.error(e);
        }
    }

    async function loadProductsOverall() {
        try {
            const querySnapshot = await firestore
                .collection(`business/${provider.business.id}/${statsK}/products/overall`)
                .where('createdAt', '>=', dateRange.start.toDate())
                .where('createdAt', '<=', dateRange.end.toDate())
                .orderBy('createdAt', 'asc')
                .get();

            const productsStats = productsOverallDefinition(dateRangeLabels);
            const products = handleDefinition(querySnapshot, productsStats);

            setRawData((_data) => ({ ..._data, productsOverall: products }));

            const _data = {};
            Object.keys(productsStats).forEach((key) => {
                _data[key] = !productsStats[key]?.handle
                    ? Object.values(productsStats[key])
                    : productsStats[key];
            });
            addData('storeProductsOverallproductsOverall', _data);
        } catch (e) {
            console.error(e);
        }
    }

    async function loadProductsStats() {
        try {
            const querySnapshot = await firestore
                .collectionGroup(`${statsK !== 'stats' ? 'a' : ''}sold`)
                .where('businessId', '==', provider.business.id)
                .where('createdAt', '>=', dateRange.start.toDate())
                .where('createdAt', '<=', dateRange.end.toDate())
                .orderBy('createdAt', 'asc')
                .get();

            const soldProductsData = [];

            querySnapshot.forEach((doc) => {
                soldProductsData.push({
                    id: doc.id,
                    ...doc.data(),
                    productId: doc.ref.parent.parent.id,
                    docType: doc.ref.parent.parent.parent.parent.id
                });
            });

            const soldProductsStats = soldProductsDefinition(dateRangeLabels);
            const soldProducts = handleDefinition(soldProductsData, soldProductsStats);
            setRawData((_data) => ({ ..._data, storeProducts: soldProducts }));

            // TODO: Centralizzare
            const _data = {};
            Object.keys(soldProductsStats).forEach((key) => {
                if (!soldProductsStats[key]?.handle) {
                    if (soldProductsStats[key]?.mutator) {
                        _data[key] = Object.keys(soldProductsStats[key])
                            .filter((j) => j !== 'mutator')
                            .map((subKey) =>
                                soldProductsStats[key].mutator(soldProductsStats[key][subKey])
                            );
                    } else {
                        _data[key] = Object.values(soldProductsStats[key]);
                    }
                } else {
                    _data[key] = soldProductsStats[key];
                }
            });
            addData('soldProducts', _data);
        } catch (e) {
            console.error(e);
        }
    }

    const state = React.useMemo(
        () => ({
            load,
            data,
            ...data,
            updateStats,
            dateRange,
            updateDateRange,
            dateRangeLabels
        }),
        [data, dateRange, dateRangeLabels, updateDateRange, updateStats]
    );

    return <StatsDataContext.Provider value={state}>{children}</StatsDataContext.Provider>;
}

export default StatsData;

export const useStatsData = () => useContext(StatsDataContext);
