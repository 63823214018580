import React, { createContext, useEffect, useState, useContext } from 'react';
import StateContext, { firestore } from '@/store/StateContext';

export const ProductsAndMenuContext = createContext({});
function ProductsAndMenusProvider({ children }) {
    const { business } = useContext(StateContext);
    const [products, setProducts] = useState(null);
    const [storeProducts, setStoreProducts] = useState(null);
    const [menus, setMenus] = useState(null);
    const [tables, setTables] = useState(null);

    useEffect(() => {
        if (!business) return;

        loadProducts();
        loadMenus();
        loadStoreProducts();
    }, [business]);

    async function loadProducts() {
        try {
            if (!business) return;

            const productsQuerySnapshot = await firestore
                .collection(`business/${business.id}/products`)
                .orderBy('order', 'asc')
                .get();

            const loadedProducts = {};
            productsQuerySnapshot.forEach((doc) => {
                loadedProducts[doc.id] = { ...doc.data(), id: doc.id };
            });

            setProducts(loadedProducts);
        } catch (e) {
            console.error(e);
        }
    }

    async function loadStoreProducts() {
        try {
            if (!business) return;

            const productsQuerySnapshot = await firestore
                .collection(`business/${business.id}/storeProducts`)
                .orderBy('order', 'asc')
                .get();

            const loadedProducts = {};
            productsQuerySnapshot.forEach((doc) => {
                loadedProducts[doc.id] = { ...doc.data(), id: doc.id };
            });

            setStoreProducts(loadedProducts);
        } catch (e) {
            console.error(e);
        }
    }
    async function loadMenus() {
        try {
            if (!business) return;

            const productsQuerySnapshot = await firestore
                .collection(`business/${business.id}/menus`)
                .orderBy('order', 'asc')
                .get();

            const loadedMenus = {};
            productsQuerySnapshot.forEach((doc) => {
                loadedMenus[doc.id] = { ...doc.data(), id: doc.id };
            });

            setMenus(loadedMenus);
        } catch (e) {
            console.error(e);
        }
    }

    async function loadTables() {
        let querySnapshot = await firestore
            .collection('qrCodes')
            .where('businessId', '==', business.id)
            .get();

        if (querySnapshot.empty) return setTables([]);

        setTables(
            querySnapshot.docs
                .map((doc, index) => {
                    return { ...doc.data(), id: doc.id };
                })
                .sort((i, j) => i.name.localeCompare(j.name, 'it', { numeric: true }))
        );
    }

    function updateProducts(products) {
        setProducts(products);
    }

    function updateProduct(id, product) {
        setProducts((_products) => ({ ..._products, [id]: product }));
    }

    function updateMenu(id, menu) {
        setMenus((_menus) => ({ ..._menus, [id]: menu }));
    }

    function updateMenus(menus) {
        setMenus(menus);
    }

    const state = React.useMemo(
        () => ({
            menusObj: menus,
            menus: Object.values(menus || {}),
            productsObj: products,
            products: Object.values(products || {}),
            storeProductsObj: storeProducts,
            storeProducts: Object.values(storeProducts || {}),
            tables,
            loadStoreProducts,
            loadMenus,
            loadProducts,
            loadTables,
            updateProducts,
            updateProduct,
            updateMenu,
            updateMenus
        }),
        [menus, products, storeProducts, tables]
    );

    return (
        <ProductsAndMenuContext.Provider value={state}>{children}</ProductsAndMenuContext.Provider>
    );
}

export default ProductsAndMenusProvider;

export const useProductsAndMenus = () => useContext(ProductsAndMenuContext);
