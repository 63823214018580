import { useContext, useEffect } from 'react';
import StateContext from '@/store/StateContext';
import { useHistory } from 'react-router-dom';

function IsAuth({ children }) {
    const context = useContext(StateContext);
    const history = useHistory();

    useEffect(() => {
        if (!context.currentUser) return history.push('/auth/login');
    });

    return <>{children}</>;
}

export default IsAuth;
